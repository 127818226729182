export const seasons = [
    {
        name: 'Winter',
    },
    {
        name: 'Autumn',
    },
    {
        name: 'Summer',
    },
    {
        name: 'Spring',
    },
];

export const months = [
    {
        abbreviation: 'Jan',
        name: 'January',
        season: 0,
    },
    {
        abbreviation: 'Feb',
        name: 'February',
        season: 0,
    },
    {
        abbreviation: 'Mar',
        name: 'March',
        season: 1,
    },
    {
        abbreviation: 'Apr',
        name: 'April',
        season: 1,
    },
    {
        abbreviation: 'May',
        name: 'May',
        season: 1,
    },
    {
        abbreviation: 'Jun',
        name: 'June',
        season: 2,
    },
    {
        abbreviation: 'Jul',
        name: 'July',
        season: 2,
    },
    {
        abbreviation: 'Aug',
        name: 'August',
        season: 2,
    },
    {
        abbreviation: 'Sep',
        name: 'September',
        season: 3,
    },
    {
        abbreviation: 'Oct',
        name: 'October',
        season: 3,
    },
    {
        abbreviation: 'Nov',
        name: 'November',
        season: 3,
    },
    {
        abbreviation: 'Dec',
        name: 'December',
        season: 0,
    }
];

export const weekdays = [
    { weekday: 0, name: 'Sunday', abbreviation: 'Su', color: '#ff0000', isWeekend: true },
    { weekday: 1, name: 'Monday', abbreviation: 'Mo', color: '#ffa500', isWeekend: false },
    { weekday: 2, name: 'Tuesday', abbreviation: 'Tu', color: '#ffff00', isWeekend: false },
    { weekday: 3, name: 'Wednesday', abbreviation: 'We', color: '#008000', isWeekend: false },
    { weekday: 4, name: 'Thursday', abbreviation: 'Th', color: '#0000ff', isWeekend: false },
    { weekday: 5, name: 'Friday', abbreviation: 'Fr', color: '#4b0082', isWeekend: false },
    { weekday: 6, name: 'Saturday', abbreviation: 'Sa', color: '#ee82ee', isWeekend: true },
];
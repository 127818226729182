const appSecret = '9ndrhip9gg4csij27k84ldiv26';
const appKey = 'db02';
const apiUrl = 'https://library.projects.mory-soft.com/api/';

export async function getDocument(name) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const response = await fetch(`${apiUrl}?action=${appKey}&key=${name}&PHPSESSID=${appSecret}`, requestOptions);
    const result = await response.json();

    if (result.code) {
        throw new Error(`${result.code} - ${result.message}`);
    }

    return result.data;
}

export async function setDocument(name, data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(`${apiUrl}?action=${appKey}&key=${name}&PHPSESSID=${appSecret}`, requestOptions);
    const result = await response.json();

    if (result.code) {
        throw new Error(`${result.code} - ${result.message}`);
    }

    return result.data;
}

export const api = {
    getDocument,
    setDocument,
};
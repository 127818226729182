import './Admin.scss';
import { useCallback, useState } from 'react';
import { childrenProvider, useBL } from '../bl';
import { FaMinusSquare, FaPlusSquare, FaStar } from 'react-icons/fa';

const maxCount = 10;

function AdminDate({ date, onChange }) {
  const [value, setValue] = useState(date);
  const [editMode, setEditMode] = useState(false);

  const onClick = useCallback(() => {
    onChange();
    setEditMode(false);
  }, [onChange]);

  const onInputChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  if (editMode) {
    return (
      <span>
        <input type="datetime-local" value={value} onChange={onInputChange} />
        <button type="button" onClick={onClick}>Save</button>
      </span>
    );
  } else {
    return (
      <span>
        {date}
        <button type="button" onClick={() => setEditMode(true)}>Edit</button>
      </span>
    );
  }
}

function AdminOptions({ selected, options, onChange }) {
  return (
    <span>{options.map(o => (
      <button type="button" onClick={onChange.bind(this, o)} disabled={o === selected}>{o}</button>
    ))}</span>
  );
}

const countdownStyles = ['default', 'allowed', 'forbidden'];
// const goalTypes = ['date', 'money', 'stars'];

function Admin() {
  const [children, setChildren, state] = useBL(childrenProvider, [], []);

  const onAddStars = useCallback((name, add) => {
    setChildren(rs => rs.map(r => ({
      ...r,
      stars: r.stars + (r.name === name ? add : 0),
    })));
  }, [setChildren]);

  const onAddMoney = useCallback((name, add) => {
    setChildren(rs => rs.map(r => ({
      ...r,
      money: r.money + (r.name === name ? add : 0),
    })));
  }, [setChildren]);

  const onChangeCountdownStyle = useCallback((childName, countdownName, selectedStyle) => {
    setChildren(children => children.map(({ countdowns, ...child }) => ({
      ...child,
      countdowns: countdowns.map(({ style, ...countdown }) => ({
        ...countdown,
        style: (child.name === childName && countdown.name === countdownName) ? selectedStyle : style,
      })),
    })));
  }, [setChildren]);

  const onConvertStars = useCallback(() => {
    if (!window.confirm('Are you sure?')) {
      return false;
    }

    setChildren(rs => rs.map(r => ({
      ...r,
      money: r.money + r.stars,
      stars: 5,
    })));
  }, [setChildren]);

  return (
    <fieldset className="fd-admin fd-page" disabled={state !== 'ready'}>
      <h4>{new Date().toString()}</h4>
      <div className="fd-admin-children">
        {children.map(({ name, stars, money, countdowns, ...child }) => (
          <div key={name} className="fd-admin-child">
            <h1 className="fd-admin-child-name">{name}</h1>
            <div className="fd-admin-child-stars">
              <h2>Stars: <b><FaStar /> {stars}</b></h2>
              <span className="fd-admin-child-actions">
                <span>
                  <button disabled={stars === 0} onClick={() => onAddStars(name, -1)}>
                    <FaMinusSquare />
                  </button>
                  <button disabled={stars === maxCount} onClick={() => onAddStars(name, +1)}>
                    <FaPlusSquare />
                  </button>
                </span>
              </span>
            </div>
            <hr />
            <div className="fd-admin-child-money">
              <h2>Money: <b>$ {money}</b></h2>
              <span className="fd-admin-child-actions">
                {[0, 1].map(p => <span key={p}>
                  {[1, 2, 5].map(m => {
                    const value = Math.pow(10, p) * m;

                    return <span key={m}>
                      <button disabled={money < value} onClick={() => onAddMoney(name, -value)}>
                        <FaMinusSquare /> {value}
                      </button>
                      <button onClick={() => onAddMoney(name, +value)}>
                        <FaPlusSquare /> {value}
                      </button>
                    </span>;
                  })}
                </span>)}
              </span>
            </div>
            <hr />
            <div className="fd-admin-child-countdowns">
              <h2>Countdowns</h2>
              {countdowns.map(countdown => (
                <div key={countdown.name}>
                  <h3>{countdown.name} <small>{countdown.created}</small></h3>
                  <div>
                    <b>Style:</b>
                    <AdminOptions
                      selected={countdown.style}
                      options={countdownStyles}
                      onChange={(selected, e) => onChangeCountdownStyle(name, countdown.name, selected)} />
                  </div>
                  <div><b>Date:</b> <AdminDate date={countdown.date} /></div>
                  <div><b>Icon:</b> {countdown.icon}</div>
                  {/* <pre>{JSON.stringify(countdown, undefined, 4)}</pre> */}
                </div>
              ))}
            </div>
            <hr />
            <pre>{JSON.stringify(child, undefined, 4)}</pre>
          </div>
        ))}
      </div>
      <div>
        <button onClick={onConvertStars}>Convert stars to money</button>
      </div>
    </fieldset>
  );
}

export default Admin;

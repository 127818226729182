import './Rating.scss';
import { FaRegStar } from 'react-icons/fa';
import { childrenProvider, useBL } from '../bl';

// function Stars({ count }) {
//     return Array(count)
//         .fill(true, 0, count)
//         .map((on, i) => <FaStar key={i} />);
// }

export function Rating() {
    const [rates] = useBL(childrenProvider, [], []);

    return (
        <div className={`fd-rating`}>
            {rates.map((rate, i) => (
                <div key={rate.name}
                    className={`fd-rating-item`}
                    style={{
                        direction: i ? 'ltr' : 'rtl',
                        //backgroundImage: `linear-gradient(rgb(255 255 255 / 80%), rgb(0 0 0 / 80%)), url('${rate.pattern}')`,
                        color: rate.accent,
                    }}>
                    <div className="fd-rating-cover" style={{ backgroundImage: `url('${rate.pattern}')` }}>
                        <div className={`fd-rating-avatar`} style={{ backgroundImage: `url('${rate.avatar}')` }}></div>
                    </div>
                    <div className="fd-rating-money">
                        <span className="fd-rating-money-amount">$ {rate.money}</span>
                    </div>
                    <div className="fd-rating-points">
                        <FaRegStar /> {rate.stars}
                    </div>
                    <div className="fd-rating-countdowns">
                        {([
                            ...(rate.countdowns || []).map(({ date, ...timer }) => ({ ...timer, type: 'date', value: date })),
                            ...(rate.gifts || []).map(({ goal, ...gift }) => ({ ...gift, style: 'default', type: goal.type, value: goal.value })),
                        ]).map(countdown => {
                            const now = new Date();
                            const created = new Date(countdown.created);

                            let total, elapsed, inner, decremental = false;

                            switch (countdown.style) {
                                case 'allowed':
                                    decremental = true;
                                    break;
                                case 'forbidden':
                                    decremental = true;
                                    break;
                                default:
                                    decremental = false;
                                    break;
                            }

                            switch (countdown.type) {
                                case 'date':
                                    total = (new Date(countdown.value).getTime() - created.getTime()) / 1000;
                                    elapsed = (now.getTime() - created.getTime()) / 1000;
                                    inner = (<span className="fd-rating-countdown-inner-date">{Math.round(elapsed)}/{Math.round(total)}</span>);
                                    break;
                                case 'stars':
                                    total = countdown.value;
                                    elapsed = rate.stars;
                                    inner = (<span className="fd-rating-countdown-inner-stars">{elapsed}/{total}</span>);
                                    break;
                                case 'money':
                                    total = countdown.value;
                                    elapsed = rate.money;
                                    inner = (<span className="fd-rating-countdown-inner-money">{elapsed}/{total}</span>);
                                    break;
                                default:
                                    total = 1;
                                    elapsed = 0;
                                    inner = (<span></span>);
                                    break;
                            }

                            const progress = Math.round(Math.min(elapsed / total, 1) * 100);
                            const rotLeft = (decremental
                                ? progress > 50 ? 100 : (progress + 50)
                                : Math.min(progress, 50)) * 3.6;
                            const rotRight = (decremental
                                ? Math.min(progress, 100)
                                : progress > 50 ? (progress - 50) : 0) * 3.6;

                            return ({ ...countdown, progress, decremental, rotLeft, rotRight, inner });
                        }).map(countdown => (
                            <div key={countdown.name}
                                title={countdown.name}
                                className={`fd-rating-countdown fd-rating-countdown-gift fd-rating-countdown-${countdown.type} fd-rating-countdown-${countdown.style} fd-rating-countdown-${countdown.progress} circular`}
                            >
                                <div class="inner" style={{ backgroundImage: `url('${countdown.icon}')` }}></div>
                                {/* <div class="number">{countdown.name}<br />{countdown.inner}<br/>{countdown.progress}%</div> */}
                                <div class="circle">
                                    <div class="bar left">
                                        <div class="progress" style={{ transform: `rotate(${countdown.rotLeft}deg)` }}></div>
                                    </div>
                                    <div class="bar right">
                                        <div class="progress" style={{ transform: `rotate(${countdown.rotRight}deg)` }}></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

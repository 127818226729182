import './App.scss';
import { useEffect, useState } from 'react';
import { Birthdays } from './sections/Birthdays';
// import { Weekdays } from './sections/Weekdays';
import { Rating } from './sections/Rating';
import { Clock } from './sections/Clock';
import Admin from './sections/Admin';
import { FaFingerprint, FaPause, FaPlay } from 'react-icons/fa';

const widgets = [
  { name: 'Birthdays', timeout: 10000, render: function ({ date }) { return (<Birthdays date={date} />); } },
  // { name: 'Weekdays', timeout: 10000, render: function ({ date }) { return (<Weekdays date={date} />); } },
  { name: 'Rating', timeout: 10000, render: function ({ date }) { return (<Rating />); } },
  { name: 'Clock', timeout: 10000, render: function ({ date }) { return (<Clock date={date} />); } },
];
const admin = { name: 'Admin', timeout: 60000, render: function ({ date }) { return (<Admin />); } };

function App() {
  const [active, setActive] = useState(widgets[0]);
  const [date, setDate] = useState(new Date());
  const [autoSlide, setAutoSlide] = useState(1);

  useEffect(() => {
    const th = setTimeout(() => {
      setDate(new Date());
    }, 2000);

    return () => {
      clearTimeout(th);
    }
  }, [date]);
  
  useEffect(() => {
    const th = autoSlide && setTimeout(() => {
      setActive(widgets[(widgets.indexOf(active) + 1) % widgets.length]);
    }, active.timeout / autoSlide);

    return () => {
      clearTimeout(th);
    }
  }, [active, autoSlide]);

  return (
    <div className="fd-app">
      <header className="fd-app-header">
        <nav className="fd-app-nav">
          <button type="button" className={`fd-app-nav-item fd-app-nav-item-action`} onClick={() => setAutoSlide(x => x ? 0 : 1)}>
            {autoSlide ? <FaPause /> : <FaPlay />}
          </button>
          <button type="button" className={`fd-app-nav-item fd-app-nav-item-action`} onClick={() => setAutoSlide(x => ([1, 2, 3, 1])[x])}>
            x{autoSlide || 1}
          </button>
          {widgets.map(w => (
            <button key={w.name} type="button" className={`fd-app-nav-item ${w === active ? 'fd-app-nav-item-active' : ''}`} onClick={() => setActive(w)}>
              {w.name}
            </button>
          ))}
          <button type="button" className={`fd-app-nav-item fd-app-nav-item-action ${active === admin ? 'fd-app-nav-item-active' : ''}`} onClick={() => setActive(admin)}>
            <FaFingerprint />
          </button>
        </nav>
      </header>
      <div className="fd-app-sections">
        {active.render({ date })}
      </div>
      {/* <footer className="fd-app-footer">
        Family Dashboard &copy; 2021 / {date.toLocaleString()}
      </footer> */}
    </div>
  );
}

export default App;

import './Clock.scss';
import { useMemo } from 'react';
import { weekdays, months, seasons } from '../constants';

const timepoints = [
    {
        name: 'School',
        isToday: (date, weekday) => !weekday.isWeekend,
        time: new Date(0, 0, 1, 8, 5),
    },
    {
        name: 'Breakfast',
        isToday: (date, weekday) => weekday.isWeekend,
        time: new Date(0, 0, 1, 9, 0),
    },
    {
        name: 'Sleep',
        isToday: (date, weekday) => true,
        time: new Date(0, 0, 1, 22, 0),
    },
];

function DigitalClock({ time }) {
    const hours = time.getHours();
    const minutes = time.getMinutes();

    return (
        <span className="fd-clock-digital">
            <span className="fd-clock-hours">{hours > 9 ? '' : '0'}{hours}</span>
            <span className="fd-clock-minutes">{minutes > 9 ? '' : '0'}{minutes}</span>
        </span>
    );
}

/**
 * @param {object} props
 * @param {Date} props.date
 */
export function Clock({ date }) {
    const month = useMemo(() => date.getMonth(), [date]);
    const season = useMemo(() => months[month].season, [month]);
    const weekday = useMemo(() => date.getDay(), [date]);

    const hr = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    let hrPosition = (hr * 360) / 12 + (min * (360 / 60)) / 12;
    let minPosition = (min * 360) / 60 + (sec * (360 / 60)) / 60;
    let secPosition = (sec * 360) / 60;

    // Set each position when the function is called
    hrPosition = hrPosition + 3 / 360;
    minPosition = minPosition + 6 / 60;
    secPosition = secPosition + 6;

    return (
        <div className="fd-page fd-calendar">
            <div className="fd-months fd-stack">
                {months.map((m, i) => (
                    <div className={`fd-month fd-season-${months[i].season} fd-item`}>
                        <Bulb state={i === month ? 'on' : 'off'} />
                        &nbsp;
                        <span>{m.name}</span>
                    </div>
                ))}
                {/* <div style={{ borderColor: today.color, color: today.color, backgroundImage: `url('/images/numberblocks/${today.weekday + 1}.png')` }}
                    className={`fd-weekdays-day fd-weekdays-day-${today.name.toLowerCase()} fd-weekdays-today ${today.isWeekend ? 'fd-weekdays-weekend' : ''}`}>
                    <div className={`fd-weekdays-day-number`}>{today.weekday + 1}</div>
                    <div className={`fd-weekdays-date-number`}>{date.getDate()}</div>
                </div> */}
            </div>

            <div className='fd-clock'>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 600 600"
                >
                    <g className='fd-clock-face'>
                        <circle className="circle" cx="300" cy="300" r="253.9" />
                        <path
                            className="hour-marks"
                            d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
                        />
                        <circle className="mid-circle" cx="300" cy="300" r="16.2" />
                    </g>
                    <g className='fd-clock-hour' style={{ transform: `rotate(${hrPosition}deg)` }}>
                        <path className="arm hour-arm" d="M300.5 298V142" />
                        <circle className="sizing-box" cx="300" cy="300" r="253.9" />
                    </g>
                    <g className='fd-clock-minute' style={{ transform: `rotate(${minPosition}deg)` }}>
                        <path className="arm minute-arm" d="M300.5 298V67" />
                        <circle className="sizing-box" cx="300" cy="300" r="253.9" />
                    </g>
                    <g className='fd-clock-second' style={{ transform: `rotate(${secPosition}deg)` }}>
                        <path className="arm second-arm" d="M300.5 350V55" />
                        <circle className="sizing-box" cx="300" cy="300" r="253.9" />
                        <circle className="mid-circle" cx="300" cy="300" r="8" />
                    </g>
                </svg>
            </div>

            <div className="fd-seasons fd-stack">
                {seasons.map((s, i) => (
                    <div className={`fd-season fd-season-${i} fd-item`}>
                        <Bulb state={i === season ? 'on' : 'off'} />
                        &nbsp;
                        <span>{s.name}</span>
                    </div>
                ))}
            </div>

            <div className="fd-clock-timepoints">
                <DigitalClock time={date} />

                {timepoints.filter(timepoint => timepoint.isToday(date, weekdays[weekday])).map(timepoint => (
                    <div className="fd-clock-timepoint" key={timepoint.name}>
                        <DigitalClock time={timepoint.time} /> <i className="fd-clock-timepoint-name">{timepoint.name}</i>
                    </div>
                ))}
            </div>

            <div className="fd-weekdays fd-stack">
                {weekdays.map((w, i) => (
                    <div className={`fd-item fd-weekdays-day fd-weekdays-day-${w.name.toLowerCase()} fd-weekdays-today ${w.isWeekend ? 'fd-weekdays-weekend' : ''}`}>
                        <Bulb state={i === weekday ? 'on' : 'off'} />
                        &nbsp;
                        <span>{w.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

/**
 * 
 * @param {{ state: 'on'|'off' }} param0 
 * @returns 
 */
function Bulb({ state }) {
    return (
        <div className={`fd-bulb fd-bulb-${state}`}></div>
    );
}

import { useEffect, useMemo, useState } from "react";
import { getDocument } from '../api';
import { MaxHeightSquare } from "../components/Square";
import './Birthdays.scss';

// const birthdays = [
//     // { name: 'Gago', date: '1988-01-07' },
//     // { name: 'Valod', date: '1986-02-29' },
//     { name: 'Vahan', date: '1988-05-31', avatar: '/images/avatars/vahan-1.png', pattern: '/images/patterns/pattern-4.png' },
//     { name: 'Hasmik', date: '1991-02-09', avatar: '/images/avatars/hasmik-1.png', pattern: '/images/patterns/pattern-1.png' },
//     { name: 'Albert', date: '2015-11-24', avatar: '/images/avatars/albert-1.png', pattern: '/images/patterns/pattern-2.png' },
//     { name: 'Shushan', date: '2017-05-20', avatar: '/images/avatars/shushan-1.png', pattern: '/images/patterns/pattern-3.png' },
// ];

export function Birthdays({ date }) {
    const [events, setEvents] = useState([]);
    const birthdays = useMemo(() => events.map(bd => {
        const dateTime = new Date(bd.date);
        const now = date;
        let next = new Date(now.getFullYear(), dateTime.getMonth(), dateTime.getDate());

        if (next < now) {
            next.setFullYear(now.getFullYear() + 1);
        }

        const diffTime = Math.abs(next - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffPerc = (1 - diffDays / 366) * 100;
        const age = (next.getFullYear() - dateTime.getFullYear()) - 1;

        return { ...bd, dateTime, next, diffTime, diffDays, diffPerc, age };
    }).sort((b1, b2) => b1.diffTime - b2.diffTime), [date, events]);

    useEffect(() => {
        let cancel = false;

        getDocument('events').then(result => {
            if (!cancel) {
                setEvents(result);
            }
        });
        
        return () => {
            cancel = true;
        }
    }, []);

    return (
        <div className={`fd-birthdays fd-page`}>
            {birthdays.map(bd => (<div key={bd.name} className={`fd-birthdays-item`}>
                <div className="fd-birthdays-age fd-birthdays-age-from">{bd.age}</div>
                {/* <div className={`fd-birthdays-title`}>{bd.name} - {bd.next.toDateString()} - {bd.diffDays}</div> */}
                <div className="fd-birthdays-bar-container">
                    <div className={`fd-birthdays-bar`} style={{ width: `${bd.diffPerc}%`, backgroundImage: `url('${bd.pattern}')` }}>
                        <div className={`fd-birthdays-avatar`} style={{ backgroundImage: `url('${bd.avatar}')` }}>
                            <MaxHeightSquare />
                        </div>
                    </div>
                </div>
                <div className="fd-birthdays-age fd-birthdays-age-to">{bd.age + 1}</div>
            </div>))}
        </div>
    );
}

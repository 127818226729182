/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { getDocument, setDocument } from './api';

export const childrenProvider = {
    get() { return getDocument('children'); },
    set(children) { return setDocument('children', children); },
}

/**
 * 
 * @param {function} provider 
 * @param {array} deps 
 * @param {*} defaultResult 
 */
export const useBL = (provider, deps, defaultResult = null) => {
    const [state, setState] = useState('initial');
    const [result, setResult] = useState(defaultResult);
    const [error, setError] = useState(null);
    const setter = useCallback(data => {
        setState('updating');

        if (typeof data === 'function') {
            data = data(result);
        }

        provider.set(data).then(res => {
            setResult(res);
            setState('ready');
        }).catch(reason => {
            setError(reason);
            setState('error');
        });;
    }, [provider, result]);

    useEffect(() => {
        let cancel = false;

        provider.get().then(res => {
            if (!cancel) {
                setResult(res);
                setState('ready');
            }
        }).catch(reason => {
            if (!cancel) {
                setError(reason);
                setState('error');
            }
        });

        setState('working');

        return () => {
            cancel = true;

            setState('cancelled');
        }
    }, [provider, ...deps]);

    return [result, setter, state, error];
};